var $ = jQuery.noConflict();

$(window).on('load', function () {
    let method = 'front';
    if ($('body').hasClass('wp-admin')) method = 'wp-admin';

    var initializeBlock = function ($block) {
        // Slider
        // ------------------------------------------------------------------------------------------------

        $('.block-hero-standard .swiper').each(function () {
            var blockId = $(this).closest('.block-hero-standard').attr('id');

            var player;

            let slideCount = $(this).find('.swiper-slide').length;

            let autoplay = $(this).data('autoplay');
            let autoplayTime = $(this).data('autoplay-time') * 1000;
            let autoplayOption = {};

            autoplayOption = {
                delay: 9999999,
            };
            if (autoplay == 1 && slideCount > 1) {
                autoplayOption = {
                    delay: autoplayTime,
                    disableOnInteraction: false,
                };
            }

            var swiperInit = new Swiper(this, {
                slidesPerView: 1,
                autoHeight: false,
                spaceBetween: 15,
                slideClass: 'swiper-slide',
                loop: false,
                autoplay: autoplayOption,
                navigation: {
                    prevEl: '#' + blockId + ' .btn-circle--prev',
                    nextEl: '#' + blockId + ' .btn-circle--next',
                },
                effect: 'fade',
                fadeEffect: {
                    crossFade: true,
                },
                speed: 600,
                on: {
                    afterInit: function (swiper) {
                        initVideo(swiper);
                        resizeVideo();
                    },
                    resize: function (swiper) {
                        resizeVideo();
                    },
                },
            });

            function resizeVideo() {
                let iframe = $('.block-hero-standard iframe');

                iframe.css({
                    width: '560px',
                    height: '315px',
                });

                let ratio = iframe.height() / iframe.width();
                let ratio_invert = iframe.width() / iframe.height();

                let iframeWidth, iframeHeight;

                let heroWidth = $('.block-hero-standard .background').width();
                let heroHeight = $('.block-hero-standard .background').height();

                iframeWidth = heroWidth;
                iframeHeight = heroWidth * ratio;

                if (iframeHeight < heroHeight) {
                    iframeWidth = heroHeight * ratio_invert;
                    iframeHeight = heroHeight;
                }

                iframe.css({
                    width: iframeWidth + 'px',
                    height: iframeHeight + 'px',
                });
            }

            function initVideo(swiper) {
                let i = swiper.activeIndex;
                let slide = swiper.slides[i];

                if ($(slide).find('iframe.vimeo').length > 0) {
                    let iframeId = $(slide).find('iframe').attr('id');
                    let vimeoId = $(slide).find('iframe').data('id');
                    let iframe = $(slide).find('iframe');

                    let options = {
                        id: vimeoId,
                        loop: true,
                    };

                    player = new Vimeo.Player(iframeId, options);
                    player.setVolume(0);
                    player.play();
                }
            }

            $(this).closest('.block-hero-standard').addClass('load');
        });

        // Services
        // ------------------------------------------------------------------------------------------------

        $(document).on(
            'click',
            '.block-hero-standard .dropdown__list li',
            function (event) {
                let url = $(this).data('url');
                let content = $(this).html();

                $('.block-hero-standard .dropdown__handle .selected').html(
                    content
                );
                $('.block-hero-standard .dropdown__handle .btn')
                    .attr('href', url)
                    .removeClass('disabled');

                if ($('.block-hero-standard .dropdown.opened').length > 0) {
                    $('.block-hero-standard .dropdown.opened').toggleClass(
                        'opened'
                    );
                }
            }
        );

        $('.block-hero-standard .dropdown__handle .selected').click(function (
            e
        ) {
            if ($(window).width() < 996) {
                $(this).parents('.dropdown').toggleClass('opened');
            }
        });

        // Counters
        // ------------------------------------------------------------------------------------------------

        function animateValue(obj, start, end, duration, separator, format) {
            let startTimestamp = null;
            let numberSpan = document.createElement('span');
            obj.appendChild(numberSpan);
            const step = (timestamp) => {
                if (!startTimestamp) startTimestamp = timestamp;
                const progress = Math.min(
                    (timestamp - startTimestamp) / duration,
                    1
                );

                let str = Number(Math.floor(progress * (end - start) + start));

                switch (format) {
                    case 'format1':
                        str = str.toLocaleString('pl-PL');
                        break;
                    case 'format2':
                        str = str.toLocaleString('de-DE');
                        break;
                    case 'format3':
                        str = str.toLocaleString('it-CH');
                        break;
                    case 'format4':
                        str = str.toLocaleString('ta-IN');
                        break;
                    case 'format5':
                        str = str.toLocaleString('zh-TW');
                        break;
                }

                str = str + separator;

                numberSpan.innerHTML = str;
                if (progress < 1) {
                    window.requestAnimationFrame(step);
                }
            };
            window.requestAnimationFrame(step);
        }

        var callback = function (entries, observer) {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    if (!entry.target.classList.contains('init')) {
                        entry.target.classList.add('init');

                        let end = entry.target.getAttribute('data-end');
                        let format = entry.target.getAttribute('data-format');

                        if (!format) format = 'standard';

                        let parts = end.split(',');
                        let duration = 2000;
                        entry.target.innerHTML = '';

                        let c = 0;
                        for (let part of parts) {
                            c++;
                            let separator = '';
                            if (c < parts.length)
                                separator =
                                    entry.target.getAttribute('data-separator');

                            duration = 2000;
                            if (part < 3) duration = 1;

                            animateValue(
                                entry.target,
                                0,
                                part,
                                duration,
                                separator,
                                format
                            );
                        }
                    }
                }
            });
        };
        const observer = new IntersectionObserver(callback, {});

        document.querySelectorAll('.counter').forEach(function (node) {
            observer.observe(node);
        });
    };

    window.onload = (function () {
        initializeBlock($(this));
    })();

    if (window.acf) {
        window.acf.addAction(
            'render_block_preview/type=hero-standard',
            initializeBlock
        );
    }
});
